import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _isNil from "lodash/isNil";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
var createHierarchyFromTree = function createHierarchyFromTree(data) {
  return data && data.map(function (rootNode) {
    var rootNodeObject = {
      key: rootNode.key
    };
    if (rootNode.children) {
      rootNodeObject.children = rootNode.children.map(function (child) {
        return {
          key: child.key
        };
      });
    }
    return rootNodeObject;
  });
};
var createPropertiesFromTree = function createPropertiesFromTree(data) {
  // Create object of properties.
  var properties = {};
  var traverseProperties = function traverseProperties(nodes, parentNode) {
    if (nodes) {
      nodes.forEach(function (node) {
        var key = node.key,
          name = node.name,
          color = node.color,
          cellIds = node.cellIds,
          cellSetKeys = node.cellSetKeys,
          rootNode = node.rootNode,
          type = node.type;
        // we either have the cellids or cellSetKeys when its a metadata cluster
        var cellIdsOrCellSetKeys = cellIds ? {
          cellIds: new Set(cellIds)
        } : {
          cellSetKeys: cellSetKeys
        };
        properties[key] = _objectSpread(_objectSpread({
          name: name,
          color: color
        }, cellIdsOrCellSetKeys), {}, {
          rootNode: rootNode,
          type: type
        });
        if (!rootNode && !_isNil(parentNode)) {
          properties[key].parentNodeKey = parentNode.key;
        }
        if (node.children) {
          traverseProperties(node.children, node);
        }
      });
    }
  };
  traverseProperties(data, null);
  return properties;
};
export { createHierarchyFromTree, createPropertiesFromTree };